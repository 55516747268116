<template>
  <div class="tool">
<!--    <div class="tool-list tool-box" @click="goNav">-->
<!--      <i class="el-icon-share"></i>-->
<!--      <span>分享得提成</span>-->
<!--    </div>-->
    <div class="tool-list tool-box" @click="showProblem">
      <i class="el-icon-warning-outline"></i>
      <span>常见问题</span>
    </div>
    <div class="tool-list">
      <div class="tool-box">
        <i class="el-icon-service"></i>
        <span>联系客服</span>

        <div class="show-tool">
          <div class="title">
            <span>联系客服</span>
          </div>
          <div class="working">工作日时间：09:00~11:30，14:00~17:30</div>
<!--          <div class="working copy-qun" :data-clipboard-text="copyText" @click="copyUrl('570002019')">-->
<!--            联系客服：-->
<!--            <span class="qun">QQ：570002019</span>-->
<!--            <i class="el-icon-document-copy" style="font-size: 14px; margin-left: 10px;"></i>-->
<!--          </div>-->
          <div class="wechat-code" v-if="customerServiceInfo && customerServiceInfo.qrCode">
            <img :src="customerServiceInfo.qrCode" alt="">
<!--            <generateQRCode :url="customerServiceInfo.qrCode" :image="require('@/assets/images/wx-service.jpg')"></generateQRCode>-->
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible"
               width="60%"
               :show-close="false"
               center
               custom-class="dialog-item"
               :modal-append-to-body='false'>
      <div slot="title" class="dialog-title">
        常见问题
        <span class="dialog-close" @click="dialogTableVisible = false">
          <i class="el-icon-close"></i>
        </span>
      </div>
      <div class="question-box">
        <div class="question-item" v-for="(item,index) in questionList" :key="index">
          <div>
            <span class="ask">问</span>
            <p class="title">{{item.question}}</p>
          </div>
          <div>
            <span>答</span>
            <p>{{item.answer}}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonApi from "@/api/common"
import generateQRCode from '@/components/generateQRCode/generateQRCode.vue'
import Clipboard from "clipboard"

export default {
  components: { generateQRCode },
  data() {
    return {
      showTool: false,
      ewm: require('@/assets/images/contact-ewm.jpg'),
      dialogTableVisible: false,
      questionList: [],
      customerServiceInfo: '',
      copyText: ''
    }
  },
  created() {
    this.getCustomerServiceInfo()
  },
  methods: {
    handleMouseEnter(){
      this.showTool = true
    },
    handleMouseLeave(){
      this.showTool = false
    },
    showProblem(){
      if(this.questionList && this.questionList.length > 0){
        this.dialogTableVisible = true
      }else {
        this.postQuestion()
      }
    },
    // 获取常见问题
    postQuestion(){
      CommonApi.question().then(res => {
        this.questionList = res.data
        this.dialogTableVisible = true
      }).catch(() => {
      })
    },
    // 获取客服信息
    getCustomerServiceInfo(){
      CommonApi.getCustomerServiceInfo().then(res => {
        this.customerServiceInfo = res.data
      }).catch(() => {
      })
    },
    // goNav(){
    //   localStorage.setItem('currentIndex',120)
    //   this.$router.push('/user_promot')
    // }
    // 复制 联系方式
    copyUrl(text){
      this.copyText = text
      let clipboard = new Clipboard( '.copy-qun')
      clipboard.on('success', (e) => {
        // 释放内存
        clipboard.destroy()
        this.$message.success('复制成功')
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // console.log('该浏览器不支持自动复制', e)
        // 释放内存
        clipboard.destroy()
      })
    },
  }
}
</script>
<style lang="less" scoped>
.tool{
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 99;
  padding: 10px 11px;
  background-color: #ffffff;
  box-shadow: -2px 2px 8px 1px rgba(0,0,0,.1);
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tool-box{
    position: relative;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i{
      font-size: 20px;
    }
    span{
      padding-top: 6px;
      font-size: 12px;
      color: rgba(0, 0, 0, .65);
    }

    .show-tool{
      display: none;
      position: absolute;
      bottom: -20px;
      right: 52px;
      width: 384px;
      min-height: 160px;
      background: #fff;
      box-shadow: 0 4px 20px 1px rgba(0,0,0,.1);
      border-radius: 12px 12px 12px 12px;
      padding: 16px;
      box-sizing: border-box;
      .title{
        font-size: 16px;
        color: #3f4654;
        text-align: center;
        margin-bottom: 13px;
        font-weight: 600;
        span{
          position: relative;
        }
      }
      .working{
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        font-size: 14px;
        color: #3c3c3c;
        .qun{
          padding: 3px 8px;
          border-radius: 3px;
          background-color: #dfefff;
          font-size: 14px;
          color: #409EFF;
        }
      }
      .wechat-code{
        margin-top: 8px;
        text-align: center;
        img{
          display: block;
          margin: auto;
          width: 100px;
        }
      }
      .tips{
        text-align: left;
        margin-top: 16px;
        background: #f7f7f7;
        color: #8c8c8c;
        font-size: 12px;
        border-radius: 8px 8px 8px 8px;
        padding: 12px 16px;
        line-height: 20px;
        .tips-title{
          text-align: left;
          font-size: 12px;
        }
        p{
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .tool-box:hover{
    .show-tool{
      display: block;
    }
  }
  .tool-list{
    padding: 8px 0;
    border-bottom: 1px solid #eaebee;
  }
  .tool-list:last-child{
    border-bottom: 0;
  }
  .tool-list:hover{
    background-color: #f9f9f9;
  }
}
.question-box{
  margin-top: 50px;
  .question-item{
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px dashed #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    >div{
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      span{
        width: 28px;
        height: 28px;
        line-height: 28px;
        background-color: #d4dbff;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        margin-right: 16px;
      }
      span.ask{
        background-color: #909fff;
      }
      p{
        flex: 1;
        line-height: 22px;
        font-size: 14px;
      }
      p.title{
        font-weight: bold;
        font-size: 14px;
        color: #909fff;
      }
    }
  }
}
/deep/ .el-dialog{
  position: relative;
  border-radius: 15px;
  overflow: auto;
  height: 80%;
  .el-dialog__header{
    position: relative;
  }
}
.dialog-title{
  position: fixed;
  top: 15vh;
  left: 50%;
  margin-left: -30%;
  width: 58%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 15px 15px 0 0;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  .dialog-close{
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}
</style>
