import request from '@/utils/request'

export default {
  // 上报日志
  report(data,key,time,md5){
    return request({
      url: '/requestlog/report',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
}
