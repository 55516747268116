<template>
  <div class="left-box">
    <ul>
      <li :class="{'active': item.active === 1}"
          v-for="item in userMenu"
          @click="goMenu(item)">{{ item.name }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  watch: {
    '$route'(to,from) {
      // 这里会在路由路径变化时执行
      // console.log('路由路径从', from.path, '变为', to.path);
      // 这里会在路由路径变化时执行
      this.getRoutePath()
    },
  },
  created() {
    this.getRoutePath()
  },
  data() {
    return {
      userMenu: [{
        active: 0,
        id: 131,
        name: '个人设置',
        path: '/user_promot/setUp'
      },{
        active: 0,
        id: 132,
        name: '我的钱包',
        path: '/user_promot/wallet'
      },{
        active: 0,
        id: 133,
        name: '我的推广',
        path: '/user_promot/income'
      },{
        active: 0,
        id: 134,
        name: '成为会员',
        path: '/user_promot/member'
      },{
        active: 0,
        name: '退出登录',
        path: ''
      }]
    }
  },
  methods: {
    getRoutePath(){
      let _this = this
      let toPath = _this.$route.path
      // console.log('toPath=',toPath)
      _this.userMenu.forEach(item => item.active = 0)
      _this.userMenu.some(item => {
        if (toPath === item.path) {
          item.active = 1
        }
      })
    },
    goMenu(item){
      if(item.path === ''){
        // 退出登录操作
        this.$confirm('确认退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.outLogin()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });
        });
      }else {
        localStorage.setItem('currentIndex',item.id)
        this.$router.push(item.path)
      }
    },
    outLogin(){
      // 模拟退出
      // this.loginTrue = false
      this.$emit('outUserLogin',{user: false})
    },
  }
}
</script>
<style scoped lang="less">
.left-box{
  width: 180px;
  border-radius: 15px;
  border: 2px solid #ffffff;
  background-color: rgba(255,255,255,0.3);
  box-shadow: 0 0 5px rgba(0, 140, 250, 0.15);
  ul{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    li{
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 14px;
      border-bottom: 1px solid #e9e9e9;
      cursor: pointer;
    }
    li:last-child{
      border-bottom: 0;
    }
    li:hover,li.active{
      color: #409EFF;
    }
    li.active::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 4px;
      height: 16px;
      margin-top: -8px;
      border-radius: 4px;
      background-color: #409EFF;
    }
  }
}
</style>
